import { useState, createRef, useContext, useRef, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import uuid from "react-uuid";

// hooks
import useToggleState from "../../hooks/useToggleState";
import { useFormDonationType } from "../../hooks/useForm";
import { useFormDelivery } from "../../hooks/useForm";

// functions
import { findGlobalContent } from "../../functions/findGlobalContent";
import { formatDate, formatNumberString } from "../../functions/formatValue";
import { AppdataContext, AppdataDispatchContext } from "../../functions/appdataContext";
import { initiateSignBankId } from "../../functions/billectaCalls";
import { saveLead } from "../../functions/leadCalls";
import { getErrorMessage } from "../../functions/getErrorMessage";
import { inputValidation } from "../../functions/inputValidation";
import { getUnmaskedValue } from "../../functions/maskString";

// components
import Button from "../Button/Button";
import CertificateDeliveryForm from "./components/CertificateDeliveryForm/CertificateDeliveryForm";
import CompanyDetailsForm from "./components/CompanyDetailsForm";
import CreateCertificateForm from "./components/CreateCertificateForm/CreateCertificateForm";
import DonationAmountForm from "./components/DonationAmountForm";
import DonationTypeForm from "./components/DonationTypeForm";
import Icon from "../Icon/Icon";
import PersonalDetailsForm from "./components/PersonalDetailsForm";
import RecurringPaymentForm from "./components/RecurringPaymentForm/RecurringPaymentForm";
import SinglePaymentForm from "./components/SinglePaymentForm";
import Alert from "../Alert/Alert";

// styles
import styles from "./Form.module.scss";

const Form = () => {
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);
  const { initialServerData, formData, formErrors, formSuccess } = appdata;
  const { amounts, global: globalData } = initialServerData || {};
  const [isOpenMemorialPreview, toggleOpenMemorialPreview] = useToggleState(false);
  const [isOpenTributePreview, toggleOpenTributePreview] = useToggleState(false);
  const [manualPersonalDetailsFormIsOpen, setManualPersonalDetailsFormIsOpen] = useState(false);
  const [manualBankAccountFormIsOpen, setManualBankAccountFormIsOpen] = useState(false);
  const [customAmount, setCustomAmount] = useState(formData?.customAmount || "");
  const [lastInteraction, setLastInteraction] = useState(undefined)
  const donationType = initialServerData?.donation_type;
  const isMonthlyDonation = formData && formData?.donation_type === "monthly";
  const donationAmountRef = createRef();
  const greetingsTextRef = createRef();
  const submitButtonRef = useRef(null);
  const stateRef = useRef();
  stateRef.current = appdata;

  // Reference of formdata to only update lead on form change
  const previousFormDataRef = useRef(formData);

  // global content keys
  const keys = {
    submitButton: isMonthlyDonation
      ? "button_registerMonthlyDonor"
      : "button_toPayment",
    privacyPolicy: "richtext_privacyPolicy",
  };

  // default values
  const texts = {
    submitButton: isMonthlyDonation ? "Bli månadsgivare" : "Till betalning",
  };

  const submitButtonProps = {
    globalData,
    key: keys.submitButton,
    defaultValue: texts.submitButton,
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    const maxLength =
      name === "phone"
        ? 25
        : name === "socialsecuritynumber"
        ? 12
        : name === "burial_date" || name === "orgnumber"
        ? 10
        : name === "clearing" || name === "zip" || name === "recipient_zip"
        ? 5
        : null;

    const inputValue =
      name === "phone" ||
      name === "account" ||
      name === "clearing" ||
      name === "orgnumber" ||
      name === "zip" ||
      name === "recipient_zip" ||
      name === "socialsecuritynumber"
        ? formatNumberString(value)
        : name === "burial_date"
        ? formatDate(value)
        : value;

    if (maxLength) {
      if (inputValue.length <= maxLength) {
        dispatch({
          type: "setFormValue",
          value: { name, value: inputValue },
        });
      }
    } else {
      dispatch({
        type: "setFormValue",
        value: { name, value: inputValue },
      });
    }
  };

  const clearRelatedFormResponseOnFormChange = (name, value) => {
    if (name === "donation_type") {
      if (value === "monthly") {
        clearFormResponse("method");
      } else if (value === "donation") {
        const keys = ["account", "clearing", "bank", "autogiro_optIn"];
        keys.forEach((name) => clearFormResponse(name));
      }
    }

    if (name === "delivery_method") {
      const keys = ["recipient_email", "recipient_firstname", "recipient_lastname", "recipient_address", "recipient_zip", "recipient_city"];
      keys.forEach((name) => clearFormResponse(name));
    }

    if (name === "delivery_recipient") {
      if (formData.delivery_method === "email") {
        const keys = ["recipient_firstname", "recipient_lastname", "recipient_address", "recipient_zip", "recipient_city"];
        keys.forEach((name) => clearFormResponse(name));
      } else {
        clearFormResponse("recipient_email");
      }
    }
  };

  const onChangeInputRadioOrCheckbox = (name, value) => {
    clearFormResponse(name);

    dispatch({
      type: "setFormValue",
      value: { name, value },
    });

    if (
      name === "amount" &&
      (value === amounts[0]?.amount ||
        value === amounts[1]?.amount ||
        value === amounts[2]?.amount)
    ) {
      setCustomAmount("");
    }

    if (name === "delivery_method" && formData?.delivery_recipient) {
      dispatch({
        type: "setFormValue",
        value: { name: "delivery_recipient", value: "" },
      });
    }

    clearRelatedFormResponseOnFormChange(name, value);

    // TODO - create better method for assuring data has been saved before triggering saveLead
    setTimeout(() => {
      triggerSaveLead();
    }, 500);
  };

  const clearFormError = (name) => {
    if (formErrors[name]) {
      dispatch({
        type: "setFormErrorValue",
        value: { name, value: "" },
      });
    }
  };

  const clearFormSuccess = (name) => {
    if (formSuccess[name]) {
      dispatch({
        type: "setFormSuccessValue",
        value: { name, value: false },
      });
    }
  };

  const clearFormResponse = (name) => {
    clearFormError(name);
    clearFormSuccess(name);
  };

  const handleInputFocus = (e) => {
    const { name, id } = e.target;
    clearFormResponse(name);
    setLastInteraction(id || name);
  };

  const handleInputValidation = (e) => {
    const { name, value } = e.target;

    const isValid = inputValidation({ name, value });

    if (isValid) {
      dispatch({
        type: "setFormSuccessValue",
        value: { name, value: true },
      });
    } else {
      dispatch({
        type: "setFormErrorValue",
        value: { name, value: getErrorMessage(name) },
      });
    }
  };

  const triggerSaveLead = () => saveLead(stateRef, dispatch);

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const previousValue = previousFormDataRef.current[name];

    // Check that value has been changed and that value isn't empty
    if (previousValue !== value && value !== "") {
      triggerSaveLead();
      previousFormDataRef.current = formData;
    }

    if (formData[name]) handleInputValidation(e);
  };

  const handlePreviewButton = async (action) => {
    if (donationType === "memorial") toggleOpenMemorialPreview();
    if (donationType === "tribute") toggleOpenTributePreview();

    const focusElement =
      action === "edit" ? greetingsTextRef.current : donationAmountRef.current;

    await new Promise((res) => setTimeout(res, 1));

    focusElement.focus();
  };

  // manage keys in formData for donation_type "monthly" & "donation"
  useFormDonationType();

  // manage keys in formData for delivery_method & delivery_recipient
  useFormDelivery();

  // WP: Submit form
  const handleSubmit = async (event) => {
    event.preventDefault();

    const canSubmit =
      formData?.donation_type !== "default" && // donation type is set
      Object.values(formData).every((x) => x !== "" && x !== false) && // no missing values
      Object.values(formErrors).every((x) => x === ""); // no errors

    if (canSubmit) {
      isMonthlyDonation ? submitMonthlyForm(event) : submitRegularForm(event);
    } else {
      if (formData?.donation_type !== "company")
        setManualPersonalDetailsFormIsOpen(true);

      if (isMonthlyDonation) setManualBankAccountFormIsOpen(true);

      if (formData?.donation_type === "default") {
        dispatch({
          type: "setFormErrorValue",
          value: { name: "donation_type", value: "Fältet är obligatoriskt" },
        });
      }

      const formDataKeys = Object.keys(formData);
      const formDataValues = Object.values(formData);

      formDataValues.forEach((value, index) => {
        if (value === "" || value === false) {
          const name = formDataKeys[index];

          dispatch({
            type: "setFormErrorValue",
            value: { name, value: "Fältet är obligatoriskt" },
          });
        }
      });
    }
  };

  // retry submit monthly payment programmatically
  const handleRetrySubmit = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  const submitMonthlyForm = async (event) => {
    console.log("submit monthly form");
    initiateSignBankId(event.target, stateRef, dispatch);
  };

  const submitRegularForm = (event) => {
    console.log("submit regular form");
    const { ajax_url, nonce, post_id } = appdata?.backendSetup || {};

    dispatch({
      type: "setSubmitIsLoading",
      value: true,
    });

    // Create session id to facillitate (secure) data population on cancelled payment.
    const session_id = initialServerData?.lead?.session_id ? initialServerData.lead.session_id : uuid();

    let form_data = new FormData(event.target);
    form_data.set("action", "ktk_donations_initiate_payment");
    form_data.set("nonce", nonce);
    form_data.set("form_id", post_id);
    form_data.set("session_id", session_id);
    form_data.set("donation_type", formData.donation_type);
    form_data.set("campaign_id", initialServerData.campaign_id);
    form_data.set("cancelUrl", `${initialServerData.current_url}?id=${formData.lead_id}&session=${session_id}`);
    form_data.set("returnUrl", initialServerData.thank_you_url);

    for (var key in formData) {
      form_data.set(key, formData[key]);
    }

    if (formData?.selected_card) {
      form_data.set(
        "card",
        JSON.stringify(
          initialServerData.pdf_array.filter(
            (element) => element.name === formData.selected_card
          )
        )
      );
    }

    if (formData?.socialsecuritynumber) {
      form_data.set("socialsecuritynumber", formData.socialsecuritynumber);
    }

    if (formData?.isMaskedPersonalDetails) {
      form_data.set("firstname", getUnmaskedValue("firstname", formData));
      form_data.set("lastname", getUnmaskedValue("lastname", formData));
      form_data.set("address", getUnmaskedValue("address", formData));
      form_data.set("zip", getUnmaskedValue("zip", formData));
      form_data.set("city", getUnmaskedValue("city", formData));
    }

    axios
      .post(ajax_url, form_data)
      .then((res) => {
        //console.log(res);
        // if success
        if (res.data.href) {
          window.location.href = res.data.href;
        }
        // if error
        if (res.data.error) {
          // Loop through all the fields with errors
          for (var index in res.data.fields) {
            dispatch({
              type: "setFormErrorValue",
              value: {
                name: res.data.fields[index],
                value: getErrorMessage(res.data.fields[index]),
              },
            });
          }
        }
        dispatch({
          type: "setSubmitIsLoading",
          value: false,
        });
      })
      .catch((err) => {
        //console.log(err);

        setTimeout(() => {
          dispatch({
            type: "setSubmitIsLoading",
            value: false,
          });
        }, 1000);
      });
  };

  useEffect(() => {
    const handleFieldInteraction = (event) => {
      const { name, id } = event.target;
      if (id || name) setLastInteraction(id || name);
    };

    const formFields = document.querySelectorAll('form textarea, form select, form button'); // for input, see handleInputFocus

    formFields.forEach(field => {
      field.addEventListener('click', handleFieldInteraction);
      field.addEventListener('focus', handleFieldInteraction);
    });

    return () => {
      formFields.forEach(field => {
        field.removeEventListener('click', handleFieldInteraction);
        field.removeEventListener('focus', handleFieldInteraction);
      });
    };
  }, []);

  useEffect(() => {
    if (lastInteraction && window.dataLayer) {
      const handleBeforeUnload = () => {
        window.dataLayer.push({
          'event': 'formAbandonment',
          'eventCategory': 'Form abandonment',
          'eventAction': 'A gift form page was abandoned',
          'lastInteractionValue': lastInteraction,
        });
      };
    
      window.addEventListener('beforeunload', handleBeforeUnload);
    
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [lastInteraction]);

  return (
    <form id="#form" onSubmit={handleSubmit} className={styles["form"]}>
      {Boolean(donationType === "memorial" || donationType === "tribute") && (
        <CreateCertificateForm
          titlePrefixNumber="1"
          globalData={globalData}
          donationType={donationType}
          isOpenMemorialPreview={isOpenMemorialPreview}
          toggleOpenMemorialPreview={toggleOpenMemorialPreview}
          isOpenTributePreview={isOpenTributePreview}
          toggleOpenTributePreview={toggleOpenTributePreview}
          pdfArray={initialServerData?.pdf_array}
          onChangeInput={onChangeInput}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          handlePreviewButton={handlePreviewButton}
          ref={greetingsTextRef}
        />
      )}

      {initialServerData?.amounts && (
        <DonationAmountForm
          titlePrefixNumber={
            Boolean(
              donationType === "default" ||
                donationType === "company" ||
                donationType === "monthly"
            ) ? "1"
              : "2"
          }
          globalData={globalData}
          ref={donationAmountRef}
          amounts={amounts}
          amount={formData?.amount}
          customAmount={customAmount}
          setCustomAmount={setCustomAmount}
          handleValidation={handleInputValidation}
          onChangeInput={onChangeInput}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
          handleInputFocus={handleInputFocus}
        />
      )}

      {donationType === "default" && (
        <DonationTypeForm
          titlePrefixNumber="2"
          globalData={globalData}
          donationType={formData?.donation_type}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
        />
      )}

      {donationType === "company" ? (
        <CompanyDetailsForm
          titlePrefixNumber="2"
          globalData={globalData}
          onChangeInput={onChangeInput}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          handleValidation={handleInputValidation}
        />
      ) : (
        <PersonalDetailsForm
          titlePrefixNumber={donationType === "monthly" ? "2" : "3"}
          globalData={globalData}
          manualPersonalDetailsFormIsOpen={manualPersonalDetailsFormIsOpen}
          setManualPersonalDetailsFormIsOpen={setManualPersonalDetailsFormIsOpen}
          triggerSaveLead={triggerSaveLead}
          onChangeInput={onChangeInput}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
        />
      )}

      {(donationType === "memorial" || donationType === "tribute") && (
        <CertificateDeliveryForm
          titlePrefixNumber="4"
          globalData={globalData}
          donationType={donationType}
          onChangeInput={onChangeInput}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          handleValidation={handleInputValidation}
        />
      )}

      {!isMonthlyDonation && (
        <SinglePaymentForm
          titlePrefixNumber={
            donationType === "company"
              ? "3"
              : donationType === "default"
              ? "4"
              : "5"
          }
          globalData={globalData}
          donationType={donationType}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
        />
      )}

      {isMonthlyDonation && (
        <Alert globalData={globalData}/>
      )}

      {isMonthlyDonation && (
        <RecurringPaymentForm
          titlePrefixNumber={
            Boolean(donationType === "monthly" || donationType === "company")
              ? "3"
              : donationType === "default"
              ? "4"
              : "5"
          }
          globalData={globalData}
          manualBankAccountFormIsOpen={manualBankAccountFormIsOpen}
          setManualBankAccountFormIsOpen={setManualBankAccountFormIsOpen}
          onChangeInput={onChangeInput}
          onChangeInputRadioOrCheckbox={onChangeInputRadioOrCheckbox}
          handleInputFocus={handleInputFocus}
          handleValidation={handleInputValidation}
          handleRetrySubmit={handleRetrySubmit}
        />
      )}

      {appdata?.submitIsLoading ? (
        <Button disabled>
          <Icon variant="loader" size="medium" />
        </Button>
      ) : (
        <Button
          type="submit"
          ref={submitButtonRef}
          value={findGlobalContent(submitButtonProps)}
          id="form-submit-button"
          isBankIdButton={isMonthlyDonation}
        >
          {findGlobalContent(submitButtonProps)}
        </Button>
      )}

      <div className={styles["privacy-policy-richtext"]}>
        {parse(findGlobalContent({ globalData, key: keys.privacyPolicy }))}
      </div>
    </form>
  );
};

export default Form;
